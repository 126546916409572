import React, {PropsWithChildren} from 'react';
import {Button} from "@/Components/ui/button";
import {Link} from "@inertiajs/react";
import useRoute from "@/Hooks/useRoute";
import {UserCogIcon} from "lucide-react";
import useTypedPage from "@/Hooks/useTypedPage";

export default function PetOnboardingLayout({children}: PropsWithChildren) {
  const {backUrl} = useTypedPage().props;
  const route = useRoute();

  return (
    <main className="min-h-[calc(100svh-2rem)] sm:h-auto max-w-md mx-auto flex flex-col justify-start w-full p-5 gap-10">
      <div className="flex items-center justify-between gap-5 -mb-10">
        <div>
          {backUrl && (
            <Button
              variant="nav"
              size="sm"
              chunky="off"
              asChild
            >
              <Link href={backUrl}>
                &lt; Back
              </Link>
            </Button>
            )}
        </div>

        <Link href={route('profile.show')}>
          <UserCogIcon className="w-6 h-6 text-muted-foreground hover:text-muted-foreground/50"/>
        </Link>
      </div>

      {children}
    </main>
  );
}
