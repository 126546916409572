import './bootstrap';
import '../css/nprogress.css';
import '../css/app.css';

import React from 'react';
import {createRoot} from 'react-dom/client';
import {createInertiaApp} from '@inertiajs/react';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import NProgress from 'nprogress'
import { router } from '@inertiajs/react'
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import weekday from 'dayjs/plugin/weekday';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import ProviderComposer from "@/Providers/ProviderComposer";
import providers from "@/Providers/providers";
import AppLayout from "@/Layouts/AppLayout";
import SendServerEventsToGTM from "@/Components/SendServerEventsToGTM";
import PetOnboardingLayout from "@/Layouts/PetOnboardingLayout";

dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Rule';

const LayoutWrapper = ({Layouts, children, pageKey}) => {
  if (typeof Layouts === 'function' && !React.isValidElement(Layouts)) {
    return <SendServerEventsToGTM key={pageKey}>{Layouts(children)}</SendServerEventsToGTM>;
  }
  const layoutArray = Array.isArray(Layouts) ? Layouts : Layouts ? [Layouts] : [];
  return layoutArray.reduceRight((child, Layout) => (
    <Layout key={pageKey}>{child}</Layout>
  ), <SendServerEventsToGTM>{children}</SendServerEventsToGTM>);
};

createInertiaApp({
  title: (title) => {
    if (!title) {
      return 'Rule';
    }

    return `${title} - ${appName}`;
  },

  progress: false,

  resolve: async (name) => {
    const pageModule = await resolvePageComponent(
      `./Pages/${name}.tsx`,
      import.meta.glob('./Pages/**/*.tsx'),
    );

    const PageComponent = pageModule.default;
    if (typeof PageComponent.layout === 'undefined') {
      if (name.startsWith('PetOnboarding/')) {
          PageComponent.layout = [PetOnboardingLayout];
      } else {
        PageComponent.layout = [AppLayout];
      }
    }

    return pageModule;
  },

  setup({el, App, props}) {
    createRoot(el).render(
      <ProviderComposer providers={providers}>
        <App {...props}>
          {({Component, props, key}) => (
            <LayoutWrapper Layouts={Component.layout} pageKey={key}>
              <Component {...props}/>
            </LayoutWrapper>
          )}
        </App>
      </ProviderComposer>
    );
  },
});


/**
 * Progress bar
 */
let timeout = null

NProgress.configure({ showSpinner: false });

router.on('start', (e) => {
  if (!e?.detail?.visit?.headers?.['N-Progress-Disable']) {
    timeout = setTimeout(() => NProgress.start(), 350);
  }
});

router.on('progress', (event) => {
  if (NProgress.isStarted() && event.detail.progress.percentage) {
    NProgress.set((event.detail.progress.percentage / 100) * 0.9);
  }
});

router.on('finish', (event) => {
  clearTimeout(timeout)
  if (!NProgress.isStarted()) {
    return
  } else if (event.detail.visit.completed) {
    NProgress.done();
  } else if (event.detail.visit.interrupted) {
    NProgress.set(0);
  } else if (event.detail.visit.cancelled) {
    NProgress.done();
    NProgress.remove();
  }
});
