import { useState, useEffect } from "react";
import {router} from "@inertiajs/core";
import useRoute from "@/Hooks/useRoute";
import {useLocalStorage} from "usehooks-ts";

function isPushNotificationSupported() {
  return "serviceWorker" in navigator && "PushManager" in window;
}

async function askUserPermission() {
  if (!('Notification' in window)) {
    console.warn('Notification API not supported.');
    return Promise.resolve('unsupported');
  }

  return await Notification.requestPermission();
}

function registerServiceWorker() {
  return navigator.serviceWorker.register("/sw.js");
}

async function createNotificationSubscription() {
  const serviceWorker = await navigator.serviceWorker.ready;
  return await serviceWorker.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: 'BBVerOVrVqcvVnfc_EzT_UTycxwfRbOUIC-S7TW4xMmL69iXLI6yrrYaee9vmyksGlHKIn5c2EnhLZhkZhwF6eE',
  });
}

async function getLocalSubscription() {
  return navigator.serviceWorker.ready
    .then(function(serviceWorker) {
      return serviceWorker.pushManager.getSubscription();
    })
    .then(function(pushSubscription) {
      return pushSubscription;
    })
    .catch(function(error) {
      console.warn("Error in getLocalSubscription", error);
    });
}

const pushNotificationSupported = isPushNotificationSupported();


export default function usePushNotifications() {
  const route = useRoute();

  let initialState = null;
  if ("Notification" in window) {
    initialState = Notification.permission;
  }

  const [userConsent, setUserConsent] = useState(initialState);
  const [localSubscription, setLocalSubscription] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [successfullySubscribed, setSuccessfullySubscribed] = useState(true);
  const [alreadySubscribed, setAlreadySubscribed] = useLocalStorage('notifications-subscribed', false);


  useEffect(() => {
    if (pushNotificationSupported) {
      setLoading(true);
      setError(null);
      registerServiceWorker().then(() => {
        setLoading(false);
      });
    }
  }, []);


  useEffect(() => {
    setLoading(true);
    setError(null);
    const getExistingSubscription = async () => {
      const existingSubscription = await getLocalSubscription();
      setLocalSubscription(existingSubscription);
      setAlreadySubscribed(!!existingSubscription);
      setLoading(false);
    };
    getExistingSubscription();
  }, []);


  const subscribe = async () => {
    setLoading(true);
    setError(null);

    const consent = await askUserPermission();
    setUserConsent(consent);

    if (consent !== "granted") {
      setError({
        name: "Consent denied",
        message: "You denied the consent to receive notifications",
        code: 0
      });
      setLoading(false);
      return false;
    }

    try {
      const subscription = await createNotificationSubscription();
      setLocalSubscription(subscription);

      router.post(route('push-subscription.store'), subscription, {
        preserveScroll: true,
        only: ['pushSubscriptions'],
        onSuccess: () => {
          setSuccessfullySubscribed(true);
        },
        onFinish: () => {
          setLoading(false);
          setAlreadySubscribed(true);
        },
        onError: (error) => {
          setError(error);
        },
      });
    } catch (error) {
      console.error("Couldn't create the notification subscription", error, "name:", error.name, "message:", error.message, "code:", error.code);
      setError(error);
      setLoading(false);
    }

    return successfullySubscribed;
  };


  const sendTestNotification = async () => {
    setLoading(true);
    setError(null);

    router.post(route('push-subscription.test-notification'), null, {
      onFinish: () => setLoading(false),
    });
  };


  return {
    subscribe,
    askUserPermission,
    sendTestNotification,
    userConsent,
    pushNotificationSupported,
    localSubscription,
    error,
    loading,
    alreadySubscribed
  };
}
